<template>
    <div style="position: relative;">
        <div class=" w-100 header" style="display: flex;justify-content: center;"
            :class="sum === 0 || sum === 1 ? 'header-content-1' : isfalg ? ' header-content isfalg-t' : 'header-content isfalg-t'">
            <div class="main-content " style="width: 1400px;">
                <div class="d-flex d-al-c d-ju-sb" style="height: 128px;width: 1400px;">
                    <div style="display: flex;align-items: center;height: 128px;">
                        <div class="login d-flex  d-al-c d-ju-c">
                            <img v-if="sum === 0 || sum === 1" width="160px" src="@/assets/logo1.png" alt="" srcset="">
                            <img v-else width="160px" src="@/assets/logo.png" alt="" srcset="">
                        </div>
                        <div class="tabbar-menu-size d-flex  d-al-c d-ju-c" v-for="(item, index) in tabbarList"
                            :class="{ activeBox: sum !== 0 && sum === index }" :key="index"
                            @click="changeTab(item.path, index)" @mouseenter="handleMouseHeaderEnt(index, $event)"
                            @mouseleave="handleMouseHeaderLeave">
                            <div>
                                <div class="tabber-title">{{ item.name }}</div>
                                <div v-if="sum == 0"
                                    style="position: absolute;right: -1px;top: 55px;width: 1px;height: 20px;background: #999;">
                                </div>
                                <div v-if="sum !== index && index < 4"
                                    style="position: absolute;right: -1px;top: 55px;width: 1px;height: 20px;background: #999;">
                                </div>
                            </div>
                            <div v-if="isfalg && index == 1"
                                style="background: #fff;width: 100%; position: absolute;left: 0px;top: 128px;z-index: 999;box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.3);">
                                <div class="w-100">
                                    <div class="main-content">
                                        <div style="display: flex;align-items: center;justify-content: space-between;">
                                            <div
                                                style="width: 320px;height: 500px;background: #f7f7f7;display: flex;align-items: center;justify-content: center;">
                                                <div style="display: flex;flex-direction: column;align-items: center;">
                                                    <img width="85px" height="85px" src="@/assets/v2/service/zy6.png"
                                                        alt="" srcset="">
                                                    <div
                                                        style="width: 120px;;font-size: 30px;color: #000;margin-top: 30px;">
                                                        AI一体化管理平台</div>
                                                </div>
                                            </div>
                                            <a href="#section3">
                                                <div
                                                style="width: 190px;height: 500px;display: flex;align-items: center;justify-content: center;">
                                                <div
                                                    style="display: flex;flex-direction: column;align-items: center;cursor: pointer;">
                                                    <img width="80px" height="80px" src="@/assets/v2/service/zy1.png"
                                                        alt="" srcset="">
                                                    <div style="font-size: 20px;color: #000;margin-top: 30px;">AI 10分钟建课
                                                    </div>
                                                </div>
                                                </div>
                                            </a>
                                            <div style="width: 1px;height: 300px;background: #f7f7f7;"></div>
                                            <a href="#section1">
                                            <div
                                                style="width: 190px;height: 500px;display: flex;align-items: center;justify-content: center;">
                                                <div
                                                    style="display: flex;flex-direction: column;align-items: center;cursor: pointer;">
                                                    <img width="80px" height="80px" src="@/assets/v2/service/zy2.png"
                                                        alt="" srcset="">
                                                    <div style="font-size: 20px;color: #000;margin-top: 30px;">数字人陪练
                                                    </div>
                                                </div>
                                            </div>
                                             </a>
                                            <div style="width: 1px;height: 300px;background: #f7f7f7;"></div>
                                            <div
                                                style="width: 190px;height: 500px;display: flex;align-items: center;justify-content: center;">
                                                <div
                                                    style="display: flex;flex-direction: column;align-items: center;cursor: pointer;">
                                                    <img width="80px" height="80px" src="@/assets/v2/service/zy3.png"
                                                        alt="" srcset="">
                                                    <div style="font-size: 20px;color: #000;margin-top: 30px;">AI多维度智能评估
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="width: 1px;height: 300px;background: #f7f7f7;"></div>
                                            <div
                                                style="width: 190px;height: 500px;display: flex;align-items: center;justify-content: center;">
                                                <div
                                                    style="display: flex;flex-direction: column;align-items: center;cursor: pointer;">
                                                    <img width="80px" height="80px" src="@/assets/v2/service/zy4.png"
                                                        alt="" srcset="">
                                                    <div style="font-size: 20px;color: #000;margin-top: 30px;">AI智能助手
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="width: 1px;height: 300px;background: #f7f7f7;"></div>
                                            <a href="#section2">
                                            <div
                                                style="width: 190px;height: 500px;display: flex;align-items: center;justify-content: center;">
                                                <div
                                                    style="display: flex;flex-direction: column;align-items: center;cursor: pointer;">
                                                    <img width="90px" height="80px" src="@/assets/v2/service/zy5.png"
                                                        alt="" srcset="">
                                                    <div style="font-size: 20px;color: #000;margin-top: 30px;">企业知识库
                                                    </div>
                                                </div>
                                            </div>
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" d-flex  d-al-c d-ju-c">
                        <!-- <div class="btn-size d-flex  d-al-c d-ju-c activeBtn" :class="sum === 0 ? 'btn-size-1' : 'btn-size'"
                            @click="changeTab('activate', -1)">免费试用</div> -->
                            <div class="btn-size d-flex  d-al-c d-ju-c activeBtn" :class="sum === 0 ? 'btn-size-1' : 'btn-size'"
                            @click="openurl">免费试用</div>
                            
                        <div class="btn-size d-flex  d-al-c d-ju-c" :class="sum === 0 ? 'btn-size-1' : 'btn-size'"
                            @click="openFront">我是学员</div>
                        <div class="btn-size d-flex  d-al-c d-ju-c" :class="sum === 0 ? 'btn-size-1' : 'btn-size'"
                            @click="openAdmin">管理端登入</div>
                    </div>
                </div>

            </div>

        </div>
        <!-- <div v-if="isfalg" style="background: #fff;width: 100%;position: absolute;left: 0px;top: 66px;z-index: 99;box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.3);" >
            <div class="w-100">
                <div class="main-content">
                    <div style="display: flex;align-items: center;justify-content: space-between;">
                        <div style="width: 320px;height: 500px;background: #f7f7f7;display: flex;align-items: center;justify-content: center;">
                            <div style="display: flex;flex-direction: column;align-items: center;">
                                <img  width="85px" height="85px" src="@/assets/v2/service/zy6.png" alt="" srcset="">
                                <div style="width: 120px;;font-size: 30px;color: #000;margin-top: 30px;">AI一体化管理平台</div>
                            </div>
                        </div>
                        <div style="width: 190px;height: 500px;display: flex;align-items: center;justify-content: center;">
                            <div style="display: flex;flex-direction: column;align-items: center;cursor: pointer;">
                                <img  width="80px" height="80px" src="@/assets/v2/service/zy1.png" alt="" srcset="">
                                <div style="font-size: 20px;color: #000;margin-top: 30px;">AI 10分钟建课</div>
                            </div>
                        </div>
                        <div style="width: 1px;height: 300px;background: #f7f7f7;"></div>
                        <div style="width: 190px;height: 500px;display: flex;align-items: center;justify-content: center;">
                            <div style="display: flex;flex-direction: column;align-items: center;cursor: pointer;">
                                <img  width="80px" height="80px" src="@/assets/v2/service/zy2.png" alt="" srcset="">
                                <div style="font-size: 20px;color: #000;margin-top: 30px;">数字人陪练</div>
                            </div>
                        </div>
                        <div style="width: 1px;height: 300px;background: #f7f7f7;"></div>
                        <div style="width: 190px;height: 500px;display: flex;align-items: center;justify-content: center;">
                            <div style="display: flex;flex-direction: column;align-items: center;cursor: pointer;">
                                <img  width="80px" height="80px" src="@/assets/v2/service/zy3.png" alt="" srcset="">
                                <div style="font-size: 20px;color: #000;margin-top: 30px;">AI多维度智能评估</div>
                            </div>
                        </div>
                        <div style="width: 1px;height: 300px;background: #f7f7f7;"></div>
                        <div style="width: 190px;height: 500px;display: flex;align-items: center;justify-content: center;">
                            <div style="display: flex;flex-direction: column;align-items: center;cursor: pointer;">
                                <img  width="80px" height="80px" src="@/assets/v2/service/zy4.png" alt="" srcset="">
                                <div style="font-size: 20px;color: #000;margin-top: 30px;">AI智能助手</div>
                            </div>
                        </div>
                        <div style="width: 1px;height: 300px;background: #f7f7f7;"></div>
                        <div style="width: 190px;height: 500px;display: flex;align-items: center;justify-content: center;">
                            <div style="display: flex;flex-direction: column;align-items: center;cursor: pointer;">
                                <img  width="90px" height="80px" src="@/assets/v2/service/zy5.png" alt="" srcset="">
                                <div style="font-size: 20px;color: #000;margin-top: 30px;">企业知识库</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div> -->
    </div>

</template>

<script>
export default {
    data() {
        return {
            tabbarList: [
                { name: '首页', path: '/', text: 'home' },
                { name: '产品服务', path: '/', text: 'home' },
                { name: '行业案例', path: 'scene', text: 'scene' },
                { name: '价格', path: 'price', text: 'price' },
                { name: '关于我们', path: 'about', text: 'about' }
            ],
            isfalg: false,
            isMouseInIsfalg: false

        }
    },

    computed: {
        sum() {
            return this.$store.state.tabIndex
        },
    },
    created() {

        // document.getElementById('option1').addEventListener('mouseover', function() {
        //     var flag = true;
        //     console.log('Flag is now:', flag);
        // });
    },
    mounted() {
        let index = this.tabbarList.findIndex(item => item.text === this.$route.name)
        this.$store.commit('setTabIndex', index)

    },
    methods: {
        openurl(){
            window.location.href="https://doc.weixin.qq.com/forms/ALcA-gcHAFwAfkAFgY0ADINO2BcjnLRjf?page=1"
        },
        openFront() {
            window.location.href = "https://ai.zhishiyiyou.com/"
        },
        openAdmin() {
            window.location.href = "https://admin.zhishiyiyou.com/"
        },
        changeTab(path, index) {
            // if(index === 1){
            //     this.isfalg = true
            // }else{
            //     this.isfalg = false
            // }

            this.$store.commit('setTabIndex', index)
            this.$router.push(path)

        },

        handleMouseHeaderEnt(index) {
            if (index === 1) {
                this.isfalg = true
            }
        },
        handleMouseHeaderLeave() {
            this.isfalg = false
        },
        onMouseOver(index) {
            if (index === 1) {
                this.isfalg = true
            } else {
                this.isfalg = false
            }
        },
        onMouseOut(index) {
            if (index === 1) {
                this.isfalg = false
            }
        },
        // onMouseOverIsfalg(value) {
        //     if (index === 1) {
        //         this.isfalg = true
        //     }
        // },
        onMouseOutIsfalg(value) {
            this.isfalg = false
        }
    }
}
</script>
<style scoped>
.fontColor {
    color: #fff;
}

.header-content {
    width: 100vw;
    /* 设置宽度等于视口宽度 */
    height: 128px;
    background: #FFFFFF;
    /* box-shadow: 0 -1px 5px rgba(0, 0, 0, 10.5); */

    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    color: #333;
}

.isfalg-t {
    box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.3);
}

.header-content-1 {
    width: 100vw;
    /* 设置宽度等于视口宽度 */
    height: 128px;
    /* background: #2b5fab; */
    /* background-image: linear-gradient(to right, #2b5fab 0%, #1e2957 100%); */
    /* box-shadow: 2px 0px 6px 0px #E8DDF6; */
    background-image: url('@/assets/v2/home/zy2.png');
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    color: #ffffff;
}

.h-100 {
    height: 100px;
}

.login {
    width: 200px;
    margin-right: 30px;
    height: 100%;
}

.tabbar-menu-size {
    width: 140px;
    height: 100%;
    cursor: pointer;
    /* margin: 0 25px; */
    /* color: #333; */
    font-size: 20px;
}

.right-btn-box {
    width: 200px;
    height: 100%;
}

.btn-size {
    width: 140px;
    height: 40px;
    border: 1px solid #666;
    /* color: #666; */
    border-radius: 20px;
    cursor: pointer;
    margin-left: 10px;
    font-size: 20px;
}

.btn-size-1 {
    width: 140px;
    height: 40px;
    border: 1px solid #fff;
    /* color: #666; */
    border-radius: 20px;
    cursor: pointer;
    margin-left: 10px;
    font-size: 20px;
}

.tabber-line {
    width: 30px;
    height: 3px;
    background: #ffffff;
}

.active {
    background: #4b81fc;
}

.activeTitle {
    color: #4b81fc;
    font-weight: 600;
}

.tabber-title {
    /* margin-top: 25px; */
}

.activeBox {
    background: #264c8e;
    color: #ffffff;
    height: 100%;
}

.activeBtn {
    /* background: #4b81fc; */
    background-image: linear-gradient(to right, #2e48c1, #2e48c1, #3d70cb);
    color: #ffffff;
    border: 1px solid #2e48c1;
    font-size: 20px;
}
</style>